<template>
    <div>
        <v-container>
            <v-row>
                <div class="col-md-12 col-sm-12">
                    <v-card dense>
                        <v-row style="margin: auto;">
                            <v-col
                                
                                cols="6"
                                lg="3"
                                md="4"
                                v-for="item in itemsProcess"
                                :key="item.FpcID"
                            >
                                <v-card class="mx-auto"
                                    dense>
                                    <v-img lazy-src="https://img.interempresas.net/fotos/2852264.jpeg"
										
										src="https://img.interempresas.net/fotos/2852264.jpeg">
                                        <v-btn
                                            style=" margin: auto; display: flex; justify-content: space-evenly; "
                                            :color="item.inColor"
                                            fab
                                            width="100"
                                            height="100"
                                            @click="openModal(item)"
                                            class="mb-8 mt-8">
                                            <span class="white--text text-h4">
                                                <b>
                                                    <h1 style="font-size:20px">
                                                    {{ item.FtmWeigthNet + 'TM' }}</h1>
                                                </b>
                                            </span>
                                        </v-btn>
                                    </v-img>
                                    <v-card style=" margin: auto; display: flex; justify-content: space-evenly; "
                                        class="white--text" 
                                        :color="item.inColor" outlined >
                                        <h3> {{ item.FpcDescription }}</h3>
                                    </v-card>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
            </v-row>
        </v-container>
        <v-dialog v-if="dialog" v-model="dialog" max-width="auto" >
            <process-detail  @close="close()" :itemdataContainer="dataContainer" :FpcID="FpcID">
                
            </process-detail>
        </v-dialog>
    </div>
</template>

<script>
    import _sProcessContainers from '@/services/FrozenProduction/FrzCtnProcessContainers.js';
    import ProcessDetail from '@/views/FrozenProduction/FrzCtnProcessContainers/FrzCtnContainersInProcessDetail.vue';
    export default{
        components: {ProcessDetail},
        data(){
            return{ 
                itemsProcess:[],
                FpcID: "",
                dataContainer:{},
                dialog: false,
            }
        },
        methods: {
            initialize()
            {
                _sProcessContainers.list({FpcArea: 1}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        r.data.forEach(element => {
                            element.ParihuelaUsagePorcentaje = (100 * element.inCarUsage) /  parseInt(element.FpcCapacity)
                            
                            if(element.ParihuelaUsagePorcentaje >= 100){
								element.inColor = 'error'
							}

							//Tunnel por llenarse
							if(element.ParihuelaUsagePorcentaje >= 80 && element.ParihuelaUsagePorcentaje < 100){
								element.inColor = 'warning'
							}

							if(element.ParihuelaUsagePorcentaje > 35 && element.ParihuelaUsagePorcentaje <= 79){
								element.inColor = 'info'
							}

							if(element.ParihuelaUsagePorcentaje <= 35){
								element.inColor = 'success'
							}
                        });
                        this.itemsProcess = r.data;                       
                    }
                })
            },
            openModal(item)
            {                
                this.dataContainer = {}
                this.FpcID = null;
                this.dataContainer = item;
                this.FpcID = item.FpcID;
                this.dialog = true;
            },
            close(){
                this.dialog = false;
                this.initialize();                
            }

            
        },
        created()
        {
            this.initialize();
        }
    }
</script>